
import { defineComponent } from "vue";

export class NodeItem {
  constructor(
    public name: string,
    public value: string,
    public isChecked: boolean,
    public childs?: Array<NodeItem>
  ) {}

  forEach(fn: (node: NodeItem) => void) {
    fn(this);
    if (!this.childs) {
      return;
    }

    this.childs.forEach((child) => child.forEach(fn));
  }
}

export default defineComponent({
  name: "TreeCheckBoxNode",
  props: {
    node: Object,
    disabled: Boolean,
  },
  methods: {
    onInputCheckBox(evt: { target: any }) {
      this.$emit("input", evt);
      this.$emit("check", evt, this.node);
    },
  },
});
