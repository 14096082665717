<template>
  <div>
    <div class="page-title">
      <h2>{{ $t("pages.applications.title") }}</h2>
      <h1>
        <router-link to="/dashboard/applications">
          <mpx-icon variant="arrow-left" />
        </router-link>
        {{ $t("pages.create_hmac_auth_application.title") }}
      </h1>
    </div>
    <div class="page-content">
      <!-- New HMAC Auth application -->
      <div class="form" v-if="section === 'new'">
        <div class="form-group">
          <label>
            {{
              $t("pages.create_hmac_auth_application.form.application.label")
            }}
          </label>
          <input type="text" class="form-control" v-model="applicationName" />
        </div>
        <div class="form-group">
          <label>
            {{ $t("pages.create_hmac_auth_application.form.company.label") }}
          </label>
          <select
            class="form-control"
            v-model="selectedCompanyId"
            v-on:change="fetchScopes"
          >
            <option value="">
              {{
                $t(
                  "pages.create_hmac_auth_application.form.company.description"
                )
              }}
            </option>
            <option
              v-for="company in companies"
              :key="company.id"
              :value="company.id"
            >
              {{ company.name }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label>
            {{ $t("pages.create_hmac_auth_application.form.scopes.label") }}
          </label>
          <div class="description">
            {{
              $t("pages.create_hmac_auth_application.form.scopes.description")
            }}
          </div>
          <br />
          <div class="scopes-container">
            <div
              v-if="scopes.length === 0 || isFetchingScopes"
              class="scopes-info"
            >
              <span v-if="isFetchingScopes">
                {{
                  $t(
                    "pages.create_hmac_auth_application.form.scopes.info.fetching"
                  )
                }}
              </span>
              <span v-else>
                {{
                  $t(
                    "pages.create_hmac_auth_application.form.scopes.info.empty"
                  )
                }}
              </span>
            </div>
            <tree-check-box v-else v-on:check="onCheckScopes" :items="scopes" />
          </div>
        </div>
        <div class="form-group">
          <button
            type="button"
            :disabled="isFetchingScopes"
            class="btn btn-primary field-submit"
            v-on:click="createHmacAuthApplication"
          >
            {{
              isSavingApplication
                ? $t("pages.create_hmac_auth_application.form.button.saving")
                : $t("pages.create_hmac_auth_application.form.button.create")
            }}
          </button>
        </div>
      </div>

      <!-- HMAC Auth application created -->
      <div class="form" v-if="section === 'created'">
        <div class="form-group">
          <label>
            {{ $t("pages.create_hmac_auth_application.form.client_id.label") }}
          </label>
          <div>
            {{ clientId }}
            <a href="#" @click="copyToClipboard(clientId)">
              <mpx-icon variant="file-copy" />
            </a>
          </div>
        </div>
        <div class="form-group">
          <label>
            {{
              $t("pages.create_hmac_auth_application.form.client_secret.label")
            }}
          </label>
          <div>
            {{ clientSecret }}
            <a href="#" @click="copyToClipboard(clientSecret)">
              <mpx-icon variant="file-copy" />
            </a>
          </div>
          <div class="description">
            {{
              $t(
                "pages.create_hmac_auth_application.form.client_secret.description"
              )
            }}
          </div>
        </div>
        <div class="form-group">
          <router-link
            class="btn btn-primary field-submit"
            :to="`/dashboard/applications/${applicationId}`"
            :style="{ textDecoration: 'none' }"
          >
            {{ $t("pages.create_hmac_auth_application.form.button.detail") }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  COMPANY_FETCH_COMPANIES,
  APPLICATION_CREATE_HMAC_AUTH,
  SCOPE_FETCH_SCOPES,
} from "@/store-types";
import MpxIcon from "@mekari/pixel/src/js/components/Icon/index.vue";
import { Company, CompanyState } from "@/interfaces/company";
import { Scope } from "@/interfaces/scope";
import TreeCheckBox, {
  Item as TreeCheckBoxItem,
} from "@/components/TreeCheckBox.vue";
import { SHOW_TOAST } from "@/store-types";
import { TYPE as ToastType } from "vue-toastification";
import { emit } from "@/utils/event";
import { event, evenType } from "@/events/mixpanel/enums/applications";
import { mixpanelInstance } from "@/utils/mixpanel";

type Section = "new" | "created";

interface Data {
  section: Section;
  applicationName: string;
  selectedCompanyId: string;
  selectedScopeIds: Set<string>;
  isSavingApplication: boolean;
  isFetchingCompanies: boolean;
  isFetchingScopes: boolean;
}

export default defineComponent({
  components: {
    MpxIcon,
    TreeCheckBox,
  },
  data(): Data {
    return {
      section: "new",
      isSavingApplication: false,
      isFetchingCompanies: false,
      isFetchingScopes: false,
      applicationName: "",
      selectedCompanyId: "",
      selectedScopeIds: new Set(),
    };
  },
  mounted() {
    this.fetchCompanies();
    // this.selectedCompanyId = '9c01354c-6b99-4563-80b1-3ad330ea7002';
    // this.fetchScopes();
  },
  computed: {
    companies(): Array<Company> {
      return this.$store.state.company.companies;
    },
    scopes(): Array<TreeCheckBoxItem> {
      const storeScopes = this.$store.state.scope.scopes;

      const parentScopeIds: Set<string | undefined> = new Set(
        storeScopes.map((scope: Scope) => scope.parentId)
      );

      const isAnyChildScopeSelected =
        Array.from(this.selectedScopeIds).filter(
          (id: string) => !parentScopeIds.has(id)
        ).length > 0;

      const scopes = storeScopes.map((scope: Scope) => ({
        ...scope,
        isChecked:
          this.selectedScopeIds.has(scope.id) && isAnyChildScopeSelected,
      }));

      return scopes;
    },
    applicationId(): string | undefined {
      return this.$store.state.application?.hmacAuthApplication?.id;
    },
    clientId(): string | undefined {
      return this.$store.state.application?.hmacAuthApplication?.clientId;
    },
    clientSecret(): string | undefined {
      return this.$store.state.application?.hmacAuthApplication?.clientSecret;
    },
  },
  methods: {
    async fetchCompanies() {
      if (this.isFetchingCompanies) {
        return;
      }

      this.isFetchingCompanies = true;

      try {
        await this.$store.dispatch(COMPANY_FETCH_COMPANIES);
      } catch (err) {
        this.handleError(err);
      } finally {
        this.isFetchingCompanies = false;
      }
    },

    async fetchScopes() {
      if (this.isFetchingScopes || !this.selectedCompanyId) {
        return;
      }

      this.isFetchingScopes = true;

      try {
        await this.$store.dispatch(SCOPE_FETCH_SCOPES, {
          companyId: this.selectedCompanyId,
          authType: "hmac_auth",
        });
      } catch (err) {
        this.handleError(err);
      } finally {
        this.isFetchingScopes = false;
      }
    },

    async createHmacAuthApplication() {
      if (this.isSavingApplication) {
        return;
      }

      this.isSavingApplication = true;

      const parentScopeIds: Set<string | undefined> = new Set(
        this.scopes.map((scope: Scope) => scope.parentId)
      );
      const scopes: Array<string> = this.scopes
        .filter(
          (scope: Scope) =>
            !parentScopeIds.has(scope.id) && this.selectedScopeIds.has(scope.id)
        )
        .map((scope: Scope) => scope.name);

      const metadata = {
        CompanySSOID: this.selectedCompanyId,
      };
      const dataEvent = {
        Remarks: "Success",
        Reason: "Success create HMAC Auth application",
      }
      try {
        const companyName =
          this.companies.find(({ id }) => id == this.selectedCompanyId)?.name ||
          this.selectedCompanyId;

        await this.$store.dispatch(APPLICATION_CREATE_HMAC_AUTH, {
          scopes,
          name: this.applicationName,
          companyId: this.selectedCompanyId,
          companyName,
        });

        this.section = "created";
        mixpanelInstance.setMetadata(metadata);
        emit(event.SubmitCreateApplication, dataEvent);
      } catch (err) {
        dataEvent.Remarks = "Failed";
        dataEvent.Reason = (err as any).response
          ? (err as any).response.data.message
          : "Something went wrong. Try again";
        mixpanelInstance.setMetadata(metadata);
        emit(event.SubmitCreateApplication, dataEvent);
        this.handleError(err);
      } finally {
        this.isSavingApplication = false;
      }
    },

    async copyToClipboard(text: string | any) {
      await navigator.clipboard.writeText(text);
    },

    handleError(err: Error | any) {
      const message = err.response
        ? err.response.data.message
        : "Something went wrong. Try again";

      this.$store.dispatch(SHOW_TOAST, {
        message: message,
        type: ToastType.ERROR,
      });
    },

    onCheckScopes(selectedScopeIds: Array<string>) {
      this.selectedScopeIds = new Set(selectedScopeIds);
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_color";

.page-title {
  padding: 22px 24px;
  background: #f3f5f7;

  h2 {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $color-gray-slate;
  }

  h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: $color-black;

    i {
      display: block;
      float: left;
      margin-right: 6px;
      margin-top: 6px;
    }
  }
}

.page-content {
  border-top: 1px solid $color-gray-smoke;
  background: $color-white;
  min-height: calc(100vh - 160px);
  padding: 24px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  .menu-container {
    h2 {
      color: $color-black;
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0em;
      color: $color-black;
    }

    .app-title {
      color: $color-black;
      font-size: 14px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0em;
      color: $color-black;
    }

    .menu-description {
      margin: 8px 0 28px 0;
    }

    .group {
      display: flex;
      justify-content: space-between;
      margin: 24px 0;
    }

    a {
      color: $color-ui-primary;
    }
  }

  .form-group {
    label {
      margin-bottom: 4px;
    }

    .form-control {
      width: 280px;
    }

    .description {
      font-weight: normal;
      font-size: 12px;
      color: $color-gray-slate;
    }
  }
}

.scopes-container {
  max-height: 200px;
  overflow: scroll;
  max-width: 480px;
  border-style: solid;
  border-color: $color-gray-smoke;
  border-left: none;
  border-right: none;

  .scopes-info {
    margin: 8px 0;
    font-weight: "normal";
    font-size: "12px";
  }
}
</style>
