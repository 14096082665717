
import { defineComponent } from "vue";
import TreeCheckBoxNode, { NodeItem } from "@/components/TreeCheckBoxNode.vue";

export interface Item {
  id: string;
  name: string;
  isChecked: boolean;
  parentId?: string;
}

interface Data {
  selectedValues: Set<string>;
}

export default defineComponent({
  name: "TreeCheckBox",
  components: {
    TreeCheckBoxNode,
  },
  props: {
    items: Array,
    disabled: Boolean,
  },
  data(): Data {
    return {
      selectedValues: new Set(),
    };
  },
  computed: {
    nodeItems(): Array<NodeItem> {
      return this.arrayItemsToNodeItems();
    },
  },
  methods: {
    arrayItemsToNodeItems(): Array<NodeItem> {
      const items = this.items as Array<Item>;
      const parentItems: Array<Item> = [];
      const nonParentItems: Array<Item> = [];

      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.parentId) {
          nonParentItems.push(item);
          continue;
        }

        parentItems.push(item);
      }

      return parentItems.map((item) => {
        const node = new NodeItem(item.name, item.id, item.isChecked);
        this.fillChildNodeItem(items, node);

        return node;
      });
    },

    fillChildNodeItem(items: Array<Item>, parent: NodeItem) {
      const parentId = parent.value;
      const childItems: Array<Item> = [];
      const nonChildItems: Array<Item> = [];

      for (let i = 0; i < items.length; i++) {
        const item = items[i];

        if (item.parentId === parentId) {
          childItems.push(item);
          continue;
        }

        nonChildItems.push(item);
      }

      parent.childs = childItems.map((item) => {
        const child = new NodeItem(item.name, item.id, item.isChecked);
        this.fillChildNodeItem(nonChildItems, child);
        return child;
      });

      const totalChilds = parent.childs.length;
      if (totalChilds === 0) {
        return;
      }

      const totalCheckedChilds = parent.childs.filter(
        (c: NodeItem) => c.isChecked
      ).length;

      parent.isChecked = totalChilds === totalCheckedChilds;
    },
    getNodeItemValues(node: NodeItem): Array<string> {
      if (!node.childs) {
        return [];
      }

      return [node.value].concat(
        node.childs.map((child) => this.getNodeItemValues(child)).flat()
      );
    },
    onCheck(
      evt: { target: { name: string; value: string; checked: boolean } },
      node: NodeItem
    ) {
      const value = evt?.target?.value || "";
      const isChecked = evt?.target?.checked || false;

      let affectedValues: Set<string> = new Set();

      this.nodeItems.forEach((node: NodeItem) => {
        node.forEach((n: NodeItem) => {
          if (n.value === value) {
            affectedValues = new Set(this.getNodeItemValues(n));
          }

          n.isChecked = affectedValues.has(n.value) ? isChecked : n.isChecked;
        });
      });

      affectedValues.forEach((value) => {
        isChecked
          ? this.selectedValues.add(value)
          : this.selectedValues.delete(value);
      });

      this.$emit("check", this.selectedValues);
    },
  },
});
