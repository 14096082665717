<template>
  <div :style="{ marginTop: '4px' }">
    <label>
      <input
        type="checkbox"
        v-on:input="onInputCheckBox"
        :name="node.name"
        :value="node.value"
        :checked="node.isChecked"
        :disabled="disabled"
      />
      {{ node.name }}
    </label>
    <div v-if="node.childs" :style="{ marginLeft: '16px' }">
      <tree-check-box-node
        v-for="childNode in node.childs"
        v-bind:key="childNode.value"
        v-on:check="$emit('check', $event, childNode)"
        :disabled="disabled"
        :node="childNode"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export class NodeItem {
  constructor(
    public name: string,
    public value: string,
    public isChecked: boolean,
    public childs?: Array<NodeItem>
  ) {}

  forEach(fn: (node: NodeItem) => void) {
    fn(this);
    if (!this.childs) {
      return;
    }

    this.childs.forEach((child) => child.forEach(fn));
  }
}

export default defineComponent({
  name: "TreeCheckBoxNode",
  props: {
    node: Object,
    disabled: Boolean,
  },
  methods: {
    onInputCheckBox(evt: { target: any }) {
      this.$emit("input", evt);
      this.$emit("check", evt, this.node);
    },
  },
});
</script>
